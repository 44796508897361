:root {
  --bs-red: #f83053;
  --bs-light-red: #ff385778;
  --pink: #ed8bfa;
  --white: #fff;
  --yellow: #f8bd1b;
  --black: #000;
  --primary: #f83053;
  --muted: #777;
  --red-gradient: linear-gradient(
    85.61deg,
    rgba(248, 48, 83, 0.91) 0.7%,
    rgba(0, 0, 0, 0.91) 100%
  );
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-link:focus,
.nav-link:hover {
  color: var(--bs-red) !important;
}

.btnColor {
  background-color: var(--bs-red) !important;
  box-shadow: 0px 4px 8px #ff385778;
}

.text-color {
  color: var(--bs-red);
}

.text-width {
  width: 490px !important;
}

.form-control:focus,
.form-select:focus,
.navbar-toggler:focus {
  box-shadow: none !important;
  border-color: var(--primary) !important;
}

.icons {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-left: 10px;
}

/* ----------------------------------- */

/* only breadcrumb css */

.breadcrumb {
  justify-content: center;
  align-items: center;
  color: var(--white);
}

.breadcrumb .card-img-overlay {
  background: var(--red-gradient);
  border-radius: 0;
  backdrop-filter: blur(1px);
}

.breadcrumb img {
  height: 400px;
  object-fit: cover;
}

.breadcrumb h1 {
  font-size: 40px;
  font-weight: 700;
  margin: 1rem 0;
  text-transform: uppercase;
  color: var(--white);
}

.breadcrumb-item a,
.breadcrumb-item a:hover {
  color: var(--white);
  text-decoration: none;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: var(--white) !important;
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  content: var(--bs-breadcrumb-divider, "»") !important;
}

.breadcrumb-item.active,
.breadcrumb p {
  color: var(--white) !important;
  opacity: 0.6;
}

.breadcrumb-item.active {
  display: flex;
  align-items: center;
  color: var(--white);
}

/* responsive css */
.place-items {
  display: grid;
  place-items: center;
  height: 100%;
}

.d-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.z-index {
  z-index: 9;
}

.py-8 {
  padding: 3rem 0;
}

/* only footer css */
footer {
  /* background-color: var(--primary); */
  background: linear-gradient(
    0deg,
    rgb(182 33 59 / 91%) 0.7%,
    rgb(6 0 0 / 91%) 100%
  );
  padding: 60px 0 35px 0;
  color: var(--white);
  z-index: 1;
  position: relative;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

#newsletter {
  background-color: rgba(255, 255, 255, 0.23) !important;
  color: #ccc !important;
}

footer a {
  transition: 100ms;
  text-decoration: none;
  color: var(--white);
}

footer a:hover {
  color: var(--yellow) !important;
}

.bg-yellow-gradient {
  background: linear-gradient(90deg, #ff9723 0%, #ff5b11 51%, #ff9723 100%);
}

.pointer {
  cursor: pointer;
}

footer h3 {
  font-size: 1.2rem;
  margin-bottom: 15px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  position: relative;
}

footer .after-line::after {
  content: "";
  position: absolute;
  height: 1px;
  top: 50%;
  margin-left: 10px;
  width: 70%;
  background-color: var(--white);
  opacity: 0.2;
}

/* only form css */
.form-control:focus {
  box-shadow: none !important;
}

#newsletter::placeholder {
  color: var(--white);
  opacity: 0.8;
}

/* only tabs css */
.rb-tabs-item.active {
  color: var(--primary);
}

/* only contact css */
.bg-gradient-red {
  width: 100%;
  background: linear-gradient(180deg, #f83053 0%, rgba(180, 85, 85, 0.31) 100%);
}

.text-green {
  color: #2ac23c;
}

.contact .btn:hover {
  border: 0;
  background: var(--white);
}

.contact .form-control,
.contact .form-select {
  border-radius: 0;
  border: 0;
}

.shadow-md {
  box-shadow: 0 16px 53px 0 rgb(190 177 215 / 56%);
}

.contact .contact-error {
  background: var(--white);
  padding-inline: 8px;
  font-weight: bold;
}

.bg-shape {
  position: absolute;
  top: 0;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  height: 600px;
  width: 200%;
  border-radius: 50%;
  background: linear-gradient(180deg, #ffffff, #ffe6ea);
}

.img-300 {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.img-200 {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

/* only blogs css */
.blogs .card-img-top {
  height: 250px;
  object-fit: cover;
  transform: scale(1.06);
  transition: all 0.3s ease;
}

.blogs2 {
  height: 450px !important;
  object-fit: cover;
}

.fisrt-latter::first-letter {
  color: var(--white);
  background-color: var(--primary);
  float: left;
  font-size: 4rem;
  line-height: 1;
  margin: 10px 15px 0 0 !important;
  padding: 12px;
}

.comment_info a {
  text-decoration: none;
  color: var(--primary) !important;
}

ul {
  list-style: none;
}

.blogs figure {
  height: 250px;
  overflow: hidden;
  position: relative;
}

.blogs .card-img-top:hover {
  transform: scale(1);
}

.blogs figure .more {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  transform: translateY(10px);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  transition: all 0.6s;
  z-index: 2;
}

.blogs a {
  text-decoration: none;
  transition: all 0.3s ease;
}

.blogs #newsletter::placeholder {
  color: var(--muted);
}

.blogs a:hover h3 {
  color: var(--primary);
}

.blogs figure:hover .more {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.blogs .widget-title {
  padding: 15px 0;
  margin-bottom: 20px;
  border-bottom: 2px solid #5c81fa;
}

.blogs .widget-title h4 {
  padding: 0;
  margin: 0;
  font-weight: 500;
  line-height: 1;
  font-size: 1rem;
}

.comments-list h3 {
  font-size: 0.875rem;
  padding: 0 0 0;
  color: var(--black);
  margin: 0;
  text-transform: capitalize;
}

.comments-list small {
  color: #555;
}

.tags a {
  background-color: #f0f0f0;
  padding: 3px 10px;
  font-size: 13px;
  margin: 0 3px 5px;
  letter-spacing: 0.4px;
  border-radius: 3px;
  display: inline-block;
  color: #333;
  cursor: pointer;
}

.tags a:hover {
  background-color: var(--primary);
  color: var(--white) !important;
}

/* only pricing css */
.pricingTable {
  color: #555;
  background: linear-gradient(
    to bottom,
    var(--primary),
    var(--primary),
    #fff,
    #fff,
    #fff,
    #fff
  );
  font-family: "Open Sans", sans-serif;
  text-align: center;
  margin: 0 0 25px;
  height: 100%;
  position: relative;
  border-radius: 0.25rem;
  transition: all 500ms;
}

.pricingTable:hover {
  transform: scale(1.03);
}

.pricingTable .pricingTable-header {
  padding: 25px 0;
}

.pricingTable .title {
  color: #fff;
  font-size: 35px;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0 0 20px;
}

.pricingTable .price-value {
  width: calc(100% - 200px);
  margin: 0 auto;
}

.pricingTable .pricing-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}

.pricingTable .pricing-content li {
  color: #272727;
  background: #efefef;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  padding: 10px 35px;
  margin: 0 0 10px;
  border-radius: 0.25rem;
  position: relative;
}

.pricingTable .pricing-content li svg {
  color: #018d24;
  font-family: "Font Awesome 5 free";
  font-size: 15px;
  margin-right: 10px;
}

.pricingTable .pricing-content li.disable svg {
  color: #e93e41;
}

.pricingTable .pricingTable-signup a {
  color: #fff;
  background: var(--primary);
  line-height: 25px;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  white-space: nowrap;
  transform: translate(-50%, 50%);
  padding: 10px 25px;
  border-radius: 0.25rem;
  display: inline-block;
  transition: all 0.3s;
}

.pricingTable.red {
  background: linear-gradient(
    to bottom,
    var(--yellow),
    var(--yellow),
    #fff,
    #fff,
    #fff,
    #fff
  );
}

.pricingTable.red .price-value .amount {
  color: #f2372b;
}

.pricingTable.red .pricingTable-signup a {
  background: var(--yellow);
}

.pricingTable.green {
  background: linear-gradient(
    to bottom,
    #06c923,
    #06c923,
    #fff,
    #fff,
    #fff,
    #fff
  );
}

.pricingTable.green .price-value .amount {
  color: #06c923;
}

.pricingTable.green .pricingTable-signup a {
  background: #06c923;
}

@media only screen and (max-width: 990px) {
  .pricingTable {
    margin: 0 0 50px;
  }
}

/* only text-truncate css */
.text-truncate2 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  -webkit-line-clamp: 2 !important;
}

.line-clamp-3 {
  -webkit-line-clamp: 3 !important;
}

.about-margin {
  margin: 47px 0px 100px 0px;
}

.ps-70 {
  padding-left: 70px;
}

.ps-50 {
  padding-left: 50px;
}

.mt-70 {
  margin-top: 70px;
}

.fs-50 {
  font-size: 50px;
}

.w-600 {
  width: 600px;
}

.w-500 {
  width: 500px;
}

/* media quary */
@media (max-width: 991.98px) {
  html,
  body {
    overflow-x: hidden;
  }

  .ps-70,
  .ps-50 {
    padding-left: 0;
    padding: 0 10px;
  }

  .text-width,
  .w-600,
  .w-500 {
    width: auto !important;
  }

  .mt-70 {
    margin-top: 20px;
  }

  .fs-50 {
    font-size: 35px;
  }

  .breadcrumb img {
    height: 250px;
  }
}
